import React, { useEffect, useRef, useState } from 'react'
import { Form, Input, Button, Checkbox, Popover, Select, Row, Col, Tabs, FormProps, Statistic, StatisticProps, ConfigProvider, message  } from 'antd'
import { login as loginApi, register as registerApi, sendCaptcha, forgetPwd, userGetImgCapcha } from '@api/index'
import { setStorage, removeStorage, getStorage, fromData, validatorUserName, validatorUserPassword, phoneReg, pwdReg, parseSearch, productApi, isBrowser } from '@utils/index'
import bgSmall from '@assets/images/bg-small.png'
import { useHistory, useLocation } from 'react-router-dom'
import { stringify } from 'qs'
import { Icon, ProFormTextCaptcha, Protocol, TextCapcha } from '..'
import loginBg from '@assets/images/login-bg.png'
import logo from '@assets/images/logo.png'
import { encryptionString } from '@utils/util';
import ProForm, {
    ModalForm,
    ProFormText,
    ProFormCaptcha,
    ProFormCheckbox,
    ProFormDependency,
    ProFormInstance,
    CaptFieldRef
} from '@ant-design/pro-form';
import { asyncSetUserInfo } from '@store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { StateType } from '@store/index'
import { fileHttpUrl } from '@api/baseUrl'
import { TOKENKEY } from '@utils/request'

const forgetPwdApi = productApi(forgetPwd)

const { Item } = Form
const { TabPane } = Tabs

const wrapperCol = { span: 20 }
const labelCol = { span: 6 }

const calcLoginRedirect = () => {
    const { search } = window?.location
    const { redirect } = parseSearch(search)
    return redirect ? redirect : '/home/index'
}

const useImgCapchaHook = () => {
    const [data, setData] = useState<any>({imgSrc: '', key: ''})
    const getImgCapchaApi = async () => {
        const key = new Date().getTime()
        const res = await userGetImgCapcha({key}) as any
        setData({imgSrc: res?.result, key})
    }
    useEffect(() => {
        getImgCapchaApi()
    }, [])
    return {
        data,
        getImgCapchaApi
    }
}

export function validatorUserConfirmPassword(rules: any, val: string, callback: Function, password: any) {
    if (!val) {
        return Promise.reject(['请输入确认密码'])
    } else if (val !== password) {
        return Promise.reject('两次密码不一致')
    }
    if (pwdReg.test(val)) {
        return Promise.resolve()
    } else {
        return Promise.reject('密码格式不正确')
    }
}

const LoginHeader = () => {
    const history = useHistory()
    const { ueSaasLogo } = useSelector((state: StateType) => state.domainInfo) as any;

    return (<div className='flex items-center justify-between' style={{height: 64,width: '100%', background: '#fff', boxShadow: '0px 8px 22px 0px rgba(0,0,0,0.05)'}}>
        <a className='ml-6 mt' onClick={() => history.push(`/`)}><img src={fileHttpUrl(ueSaasLogo)} style={{width: 80, height: 22}}></img></a>
        <span onClick={() => history.push(`/home/doc`)} className='mr-6 cursor-pointer'>帮助文档</span>
    </div>)
}

const LoginFooter = () => {
    const { ueSaasFullName, ueSaasIcpLicense } = useSelector((state: StateType) => state.domainInfo) as any;
    return (<div className='text-center text-gray-400' style={{padding: 13}}>Copyright © {ueSaasFullName} All Rights Reserved <a style={{color: 'inherit'}} href='href="https://beian.miit.gov.cn"'>{ueSaasIcpLicense}</a></div>)
}

const LoginWarp = ({children}: any) => {
    return (<div className="j-login-warp jc-antd-reset">
       <LoginHeader />
       {children}
       <LoginFooter />
    </div>)
}

// 忘记密码
const ToForgetPassWord = () => {
    const history = useHistory()
    const onClick = () => {
        history.push('/forgetPassWord')
    }
    return (<Popover open={false} title="">
        <a style={{color: '#989898'}} onClick={onClick}>忘记密码</a>
    </Popover>)
}

// 立即注册
const RegisterNow = () => {
    const history = useHistory()
    const onClick = () => {
        history.push('/register')
    }
    return (<a className='float-right' onClick={onClick}>立即注册</a>)
}
// 立即登录
const ToLogin = () => {
    const history = useHistory()
    const onClick = () => {
        history.push('/login')
    }
    return (<div className='text-center'>已有账号, <a onClick={onClick}>立即登录</a></div>)
}

const phoneRangeCodeOptions = [
    {
        label: '+86',
        value: '86'
    },
]

export const PrefixSelector = (
    <Form.Item name="prefix" noStyle initialValue={'86'}>
      <Select style={{ width: 76 }} size="large" options={phoneRangeCodeOptions} />
    </Form.Item>
);

const LoginAccountForm = ({...rest}: any) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const [form] = Form.useForm()
    const onFinish = async (values: any) => {
        const { password, ...rest } = values
        const { success, value } = await loginApi({...rest, password: encryptionString(password), captcha: ''}) as any
        if (success) {
            setStorage(TOKENKEY, value.token)
            setStorage('login-info', value)
            const willToPath = calcLoginRedirect()
            dispatch(asyncSetUserInfo()) as any // 获取账号权限信息
            history.push(willToPath)
        }
    }
    return (<Form form={form} onFinish={onFinish} {...rest}>
        <Item name="username" rules={[{ required: true, validator: validatorUserName}]}>
            <Input
               size='large'
               allowClear
               placeholder="账号"
               prefix={<Icon type="icon-gerenzhongxin" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
        </Item>
        <Item name="password" rules={[{ required: true, validator: validatorUserPassword}]}>
            <Input.Password
               size='large'
               placeholder="密码"
               autoComplete="new-password"
               prefix={<Icon type="icon-password" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
        </Item>
        <Item>
            <Button
               type="primary"
               size="large"
               htmlType="submit"
               block
               className="j-theme-button"
            >
                登录
            </Button>
        </Item>
        <div className='flex justify-between'>
            <ToForgetPassWord />
            <RegisterNow />
        </div>
    </Form>)
}

const LoginPhoneForm = ({...rest}: any) => {
    const history = useHistory()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [open, setOpen] = useState<boolean>(false)
    const captchaRef = useRef<CaptFieldRef | null | undefined>();
    // const { data, getImgCapchaApi } = useImgCapchaHook() 
    const onFinish = async (values: any) => {
        const { phone: username, ...rest } = values
        const { success, value } = await loginApi({...rest, username}) as any
        if (success) {
            setStorage(TOKENKEY, value.token)
            setStorage('login-info', value)
            const willToPath = calcLoginRedirect()
            dispatch(asyncSetUserInfo()) as any // 获取账号权限信息
            history.push(willToPath)
        }
        if (!success) {
            // getImgCapchaApi()
        }
    }

    return (<ProForm<any> form={form} onFinish={onFinish} submitter={false} {...rest}>
        <ProFormText
            name="phone"
            fieldProps={{addonBefore: PrefixSelector, size: 'large'}}
            rules={[
                { required: true, message: '请输入11位手机号码'},
                { pattern: phoneReg, message: '非法手机号' }
            ]}
        />
        {/* <ProForm.Item>
            <Row gutter={12}>
                <Col span={14}>
                    <Item
                        name="imageCaptchaCode"
                        noStyle
                        rules={[{ required: true, message: '请输入图片验证码' }]}
                    >
                        <Input size='large' placeholder='请输入图片验证码' />
                    </Item>
                </Col>
                <Col span={10}>
                    <img style={{width: '100%', height: '100%'}} src={data?.imgSrc} onClick={getImgCapchaApi} />
                </Col>
            </Row>
        </ProForm.Item> */}
        <ProFormCaptcha
            phoneName={['phone', 'imageCaptchaCode']}
            name="captcha"
            rules={[{required: true,message: '请输入验证码',}]}
            placeholder="请输入验证码"
            fieldProps={{size: 'large'}}
            captchaProps={{size: 'large'}}
            fieldRef={captchaRef}
            captchaTextRender={(timing: boolean, count: number) => {
                if (!timing) {
                    return  <Popover 
                                placement="topRight"
                                open={open}
                                overlayInnerStyle={{transform: 'translateX(64px)'}}
                                content={<ProFormTextCaptcha 
                                    name="imageCaptchaCode"
                                    fieldProps={{
                                        width: 338, 
                                        height: 150, 
                                        topTips: true,
                                        onSuccess: async () => {
                                            console.log('验证码校验通过', captchaRef)
                                            setOpen(false)
                                            const {phone: userPhone, imageCaptchaCode} = form.getFieldsValue()
                                            await sendCaptcha({userPhone, type: 1, imageCaptchaCode}) as any
                                            captchaRef.current?.startTiming()
                                            form.setFieldsValue({imageCaptchaCode: ''})
                                        }
                                    }} 
                                    rules={[{required: true, message: '请点击检验文字验证码',}]} 
                                />}
                            >
                                <span onClick={() => {
                                    const {phone: userPhone, imageCaptchaCode} = form.getFieldsValue()
                                    if (userPhone) {
                                        setOpen(!open)
                                    }
                                }}>获取验证码</span>
                            </Popover>
                }
                return `${count} 后重新获取`
            }}
            onGetCaptcha={async () => {
                const {phone: userPhone, imageCaptchaCode} = form.getFieldsValue()
                // const { result, code } = await sendCaptcha({userPhone, type: 1, imageCaptchaKey: data?.key, imageCaptchaCode}) as any
                if (userPhone && imageCaptchaCode) {
                    const { result, code } = await sendCaptcha({userPhone, type: 1, imageCaptchaCode}) as any
                    const { isRegister } = result || {} // 判断是否注册过，为0时候未注册
                    if (isRegister === 0) { // 1.4 号优化内容, 未注册等待3秒后自动跳转至注册页
                        setTimeout(() => {
                            history.push('/register')
                        }, 3000)
                    }
                    if (code < 0) {
                        // getImgCapchaApi()
                    }
                    if (code !== 0) {
                        throw new Error('不需要倒计时')
                    }
                    return 
                }
                throw new Error('不需要倒计时')
            }}
        />
        {/* <ProFormTextCaptcha name="textCaptcha" rules={[{required: true,message: '请先检验文字验证码',}]}>
            {
                ({data}: any) => {
                    return <Button size="large"block>{data?.describe}</Button>
                } 
            }
        </ProFormTextCaptcha> */}
        <Item>
            <Button
               type="primary"
               size="large"
               htmlType="submit"
               block
               className="j-theme-button"
            >
                登录
            </Button>
        </Item>
        <div className='flex justify-end'>
            <RegisterNow />
        </div>
    </ProForm>)
}


const LoginForm = () => {
    return (<div>
        {/* <h3 style={{fontSize: 24}}>登录云极控制台</h3> */}
        <Tabs defaultActiveKey="1" className='j-theme-tabs'>
            <TabPane tab={<span style={{fontSize: 20}}>账号登录</span>} key="1">
                <LoginAccountForm style={{marginTop: 12}} />
            </TabPane>
            <TabPane tab={<span style={{fontSize: 20}}>验证码登录</span>} key="2">
                <LoginPhoneForm style={{marginTop: 12}} />
            </TabPane>
        </Tabs>
    </div>)
}

const initTips = {
    username: {
        open: false,
        tips: ['6-30个字符，可以包含字母数字或汉字'],
        successTip: ['用户名合法'],
        failTip: '非法用户名',
        help: ''
    },
    password: {
        open: false,
        tips: ['6-20个字符，只能包含字母数字及标点符号', '字母数字及标点符号至少包含2种'],
        successTip: ['密码合法'],
        failTip: '非法密码',
        help: ''
    },
    confirmPassword: {
        open: false,
        tips: ['再次输入你的密码'],
        successTip: ['密码一致'],
        failTip: '两次密码不一致',
        help: ''
    },
    phone: {
        open: false,
        tips: ['11位手机号'],
        successTip: ['手机号合法'],
        failTip: '非法手机号',
        help: ''
    }
}
const TipContent = ({tips}: any) => {
    return (<div>
        {tips.map((v: any) => <p key={v}>{v}</p>)}
    </div>)
}

const ImgCaptcha = ({data, getImgCapchaApi, ...props}: any) => {
    return (<Row gutter={12}>
        <Col span={14} style={{marginRight: 10}}>
            <Input size='large' placeholder='请输入图片验证码' style={{width: '187.5px'}} {...props} />
        </Col>
        <Col >
            <img style={{width: '100%', height: '100%',}} src={data?.imgSrc} onClick={getImgCapchaApi} />
        </Col>
    </Row>)
}

const RegisterForm = ({onSubmit, ...rest}: any) => {
    const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
    const history = useHistory()
    const { search } = useLocation();
    const searchParams = parseSearch(search)
    const [form] = Form.useForm()
    const [tips, setTips] = useState<any>(initTips)
    const [open, setCaptchaOpen] = useState<boolean>(false)
    const captchaRef = useRef<CaptFieldRef | null | undefined>();
    // const { data, getImgCapchaApi } = useImgCapchaHook() 

    const onFinish = async (values: any) => {
        const { agree, confirmPassword,  phone: userPhone, password, ...rest } = values
        const { success, value } = await registerApi({userPhone, password: encryptionString(password), ...(searchParams||{}), ...rest}) as any
        if (success) {
            const { token } = value
            setStorage(TOKENKEY, value.token)
            setStorage('login-info', value)
            message.success('账号注册成功,请前往登录')
            setTimeout(() => {
                history.push(`/login`)
            }, 3000)
        }
        if (!success) {
            // getImgCapchaApi()
        }
    }
    const onFieldsChange = (changedFields: any[], allFields: any[]) => {
        const { name, errors } = changedFields[0]
        const error = errors.length
        tips[name] = { ...tips[name], tips: error ? errors : tips[name]?.successTip, help: error ? tips[name]?.failTip : '' }
        setTips({...tips})
    }
    const setOpen = (name: string, open: boolean) => {
        tips[name].open = open
        setTips({...tips})
    }
    return (<ProForm<any>
        layout="horizontal"
        submitter={false}
        // onFieldsChange={onFieldsChange}
        onFinish={onFinish}
        form={form}
        {...rest}
        >
            <Popover
               placement="right"
               align={{offset: [2, -8]}}
               content={<TipContent tips={tips['username'].tips} />}
               open={tips['username'].open}
            >
                <ProForm.Item
                    name="username"
                    label="用户名"
                    rules={[
                        { required: true, validator: validatorUserName}
                    ]}
                >
                    <Input
                       allowClear
                       size='large'
                       placeholder="请输入用户名"
                       onFocus={() => setOpen('username', false)}
                       onBlur={() => setOpen('username', false)}
                    />
                </ProForm.Item>
            </Popover>
            <Popover placement="right" align={{offset: [2, -8]}} content={<TipContent tips={tips['password'].tips} />} open={tips['password'].open}>
                <ProForm.Item name="password" label="设置密码" rules={[{ required: true, validator: validatorUserPassword}]} >
                    <Input.Password
                       allowClear
                       size='large'
                       autoComplete="new-password"
                       placeholder="设置登录密码"
                       onFocus={() => setOpen('password', false)}
                       onBlur={() => setOpen('password', false)}
                    />
                </ProForm.Item>
            </Popover>
            <ProFormDependency name={['password']}>
                {
                    ({password}) => (<Popover placement="right" align={{offset: [2, -8]}} content={<TipContent tips={tips['confirmPassword'].tips} />} open={tips['confirmPassword'].open}>
                        <ProForm.Item
                            name="confirmPassword"
                            label="确认密码"
                            rules={[
                              { required: true, validator: (...rest) => validatorUserConfirmPassword(...rest, password)}
                            ]} >
                                <Input.Password
                                    allowClear
                                    size='large'
                                    autoComplete="new-password"
                                    placeholder="确认登录密码"
                                    onFocus={() => setOpen('confirmPassword', false)}
                                    onBlur={() => setOpen('confirmPassword', false)}
                                />
                        </ProForm.Item>
                    </Popover>)
                }
            </ProFormDependency>
            <Popover placement="right" align={{offset: [2, -8]}} content={<TipContent tips={tips['phone'].tips} />} open={tips['phone'].open}>
                <ProForm.Item name="phone" label="手机号" rules={[{ required: true, message: '请输入11位手机号码'}, { pattern: phoneReg, message: '非法手机号' }]} >
                    <Input
                       allowClear
                       size='large'
                       placeholder="请输入手机号码"
                       addonBefore={PrefixSelector}
                       onFocus={() => setOpen('phone', false)}
                       onBlur={() => setOpen('phone', false)}
                    />
                </ProForm.Item>
            </Popover>
            {/* <ProForm.Item label="图片验证码" name="imageCaptchaCode" rules={[{ required: true, message: '请输入图片验证码' }]}>
                <ImgCaptcha data={data} getImgCapchaApi={getImgCapchaApi} />
            </ProForm.Item> */}
            {/* <ProFormTextCaptcha 
                label="文字验证码"
                name="textCaptcha" 
                rules={[{required: true,message: '请先检验文字验证码',}]}
            >
                {
                    ({data}: any) => {
                        return <Button size="large"block>{data?.describe}</Button>
                    } 
                }
            </ProFormTextCaptcha> */}
            <ProFormCaptcha
                phoneName={['phone', 'imageCaptchaCode']}
                name="captcha"
                label="验证码"
                fieldProps={{size: 'large'}}
                captchaProps={{size: 'large'}}
                rules={[{required: true,message: '请输入验证码',}]}
                placeholder="请输入验证码"
                fieldRef={captchaRef}
                captchaTextRender={(timing: boolean, count: number) => {
                    if (!timing) {
                        return  <Popover 
                                    placement="topRight"
                                    overlayInnerStyle={{transform: 'translateX(17px)'}}
                                    open={open}
                                    content={<ProFormTextCaptcha 
                                        name="imageCaptchaCode"
                                        fieldProps={{
                                            width: 276, 
                                            height: 150, 
                                            topTips: true,
                                            onSuccess: async () => {
                                                console.log('验证码校验通过', captchaRef)
                                                setCaptchaOpen(false)
                                                const { phone: userPhone, imageCaptchaCode } = form.getFieldsValue()
                                                await sendCaptcha({userPhone, imageCaptchaCode, type: 0}) as any
                                                captchaRef.current?.startTiming()
                                                form.setFieldsValue({imageCaptchaCode: ''})
                                            }
                                        }} 
                                        rules={[{required: true, message: '请点击检验文字验证码',}]} 
                                    />}
                                >
                                <span onClick={() => {
                                    const {phone: userPhone, imageCaptchaCode} = form.getFieldsValue()
                                    if (userPhone) {
                                        setCaptchaOpen(!open)
                                    }
                                }}>获取验证码</span>
                                </Popover>
                    }
                    return `${count} 后重新获取`
                }}
                onGetCaptcha={async () => {
                    const { phone: userPhone, imageCaptchaCode } = form.getFieldsValue()
                    if (userPhone && imageCaptchaCode) {
                        // const { code } = await sendCaptcha({userPhone, imageCaptchaCode, textCaptchaKey: data?.key, type: 0}) as any
                        const { code } = await sendCaptcha({userPhone, imageCaptchaCode, type: 0}) as any
                        // throw new Error('错误')
                        if (code < 0) {
                            // getImgCapchaApi()
                        }
                        if (code !== 0) {
                            throw new Error('不需要倒计时')
                        }
                        return
                    }
                    throw new Error('不需要倒计时')
                }}
            />
            <ProFormCheckbox.Group
                name="agree"
                rules={[{required: true, message: '请勾选同意',}]}
                wrapperCol={{offset: labelCol.span}}
                options={[{label: <span>我已阅读并同意<Protocol /></span>, value: true}]}
            />
            <Item wrapperCol={{offset: labelCol.span}}>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                block
                className="j-theme-button"
                >
                    注册
                </Button>
            </Item>
            <ToLogin />
      </ProForm>)
}

const ForgetPassWordForm = ({onSubmit, ...rest}: any) => {
    const history = useHistory()
    const [form] = Form.useForm()
    const [open, setOpen] = useState<boolean>(false)
    const captchaRef = useRef<CaptFieldRef | null | undefined>();
    // const { data, getImgCapchaApi } = useImgCapchaHook() 
    const onFinish = async (values: any) => {
        const { phone: userPhone, password, captcha } = values
        const { success } = await forgetPwdApi({ userPhone, password, captcha }, true) as any
        if (success) {
            setTimeout(() => {
                history.push(`/login`)
            }, 3000)
        }
        if (!success) {
            // getImgCapchaApi()
        }
    }
    return (<ProForm<any> form={form} onFinish={onFinish} submitter={false} {...rest}>
        <ProFormText
            name="phone"
            label="手机号"
            fieldProps={{
                size: 'large',
                addonBefore: PrefixSelector
            }}
            rules={[
                { required: true, message: '请输入11位手机号码'},
                { pattern: phoneReg, message: '非法手机号' }
            ]}
        />
        {/* <ProForm.Item label="图片验证码" name="imageCaptchaCode" rules={[{ required: true, message: '请输入图片验证码' }]}>
            <ImgCaptcha data={data} getImgCapchaApi={getImgCapchaApi} />
        </ProForm.Item> */}
        <ProFormCaptcha
            phoneName={['phone', 'imageCaptchaCode']}
            label="验证码"
            name="captcha"
            fieldProps={{size: 'large'}}
            captchaProps={{size: 'large'}}
            rules={[{required: true,message: '请输入验证码',}]}
            placeholder="请输入验证码"
            fieldRef={captchaRef}
            captchaTextRender={(timing: boolean, count: number) => {
                if (!timing) {
                    return  <Popover 
                                placement="topRight"
                                overlayInnerStyle={{transform: 'translateX(17px)'}}
                                open={open}
                                content={<ProFormTextCaptcha 
                                    name="imageCaptchaCode"
                                    fieldProps={{
                                        width: 276, 
                                        height: 150, 
                                        topTips: true,
                                        onSuccess: async () => {
                                            console.log('验证码校验通过', captchaRef)
                                            setOpen(false)
                                            const { phone: userPhone, imageCaptchaCode } = form.getFieldsValue()
                                            await sendCaptcha({userPhone, imageCaptchaCode, type: 2}) as any
                                            captchaRef.current?.startTiming()
                                            form.setFieldsValue({imageCaptchaCode: ''})
                                        }
                                    }} 
                                    rules={[{required: true, message: '请点击检验文字验证码',}]} 
                                />}
                            >
                               <span onClick={() => {
                                    const {phone: userPhone, imageCaptchaCode} = form.getFieldsValue()
                                    if (userPhone) {
                                        setOpen(!open)
                                    }
                                }}>获取验证码</span>
                            </Popover>
                }
                return `${count} 后重新获取`
            }}
            onGetCaptcha={async () => {
                const { phone: userPhone, imageCaptchaCode } = form.getFieldsValue()
                if (userPhone && imageCaptchaCode) {
                    // const { code } = await sendCaptcha({userPhone, imageCaptchaCode, imageCaptchaKey: data?.key, type: 2}) as any
                    const { code } = await sendCaptcha({userPhone, imageCaptchaCode, type: 2}) as any
                    if (code < 0) {
                        // getImgCapchaApi()
                    }
                    if (code !== 0) {
                        throw new Error('不需要倒计时')
                    }
                    return
                }
                throw new Error('不需要倒计时')
            }}
        />
        <ProFormText.Password
            name="password"
            label="设置新密码"
            fieldProps={{size: 'large', autoComplete: "new-password"}}
            rules={[
                { required: true, validator: validatorUserPassword}
            ]}
        />
        <ProFormDependency name={['password']}>
            {
                ({password}) => (<ProFormText.Password
                    name="confirmPassword"
                    label="确认新密码"
                    fieldProps={{size: 'large', autoComplete: "new-password"}}
                    rules={[
                        { required: true, validator: (...rest) => validatorUserConfirmPassword(...rest, password) }
                    ]}
                />)
            }
        </ProFormDependency>
        <Item wrapperCol={{offset: labelCol.span}}>
            <Button
                type="primary"
                size="large"
                htmlType="submit"
                block
                className="j-theme-button"
            >
             确认
            </Button>
        </Item>
    </ProForm>)
}

const Warp = ({children}: any) => {
    return (<div className='' style={{minHeight: `calc(100vh - 112px)`, background: `url(${loginBg}) center center`, backgroundSize: 'cover'}}>
            <div className='bg-white absolute top-1/4' style={{width: 370, padding: 48, paddingTop: 32, right: isBrowser() && (window.innerWidth < 768) ? '3%' : '16%'}}>
                {children}
            </div>
        </div>)
}

export const Login = () => (<LoginWarp>
    <Warp>
     <LoginForm />
   </Warp>
</LoginWarp>
)

export const Register = () => (<LoginWarp>
    <div className='w-screen mt-20' style={{marginTop: 80, minHeight: `calc(100vh - 192px)`}}>
        <div className='bg-white w-5/6 m-auto'>
           <h3 className='text-2xl text-center p-6' style={{borderBottom: '1px solid #F5F5F5', color: 'rgba(0, 0, 0, 0.65)'}}>账号注册</h3>
            <RegisterForm
               layout="horizontal"
               style={{width: 450, margin: 'auto', padding: 20}}
               wrapperCol={wrapperCol}
               labelCol={labelCol}
            />
        </div>
    </div>
</LoginWarp>)

export const ForgetPassWord = () => (<LoginWarp>
    <div className='w-screen bg-cover' style={{marginTop: 80, minHeight: `calc(100vh - 192px)`}}>
        <div className='bg-white w-5/6 m-auto pb-20'>
           <h3 className='text-2xl text-center p-6' style={{borderBottom: '1px solid #F5F5F5', color: 'rgba(0, 0, 0, 0.65)'}}>找回密码</h3>
            <ForgetPassWordForm
               layout="horizontal"
               style={{width: 450, margin: 'auto', padding: 20}}
               wrapperCol={wrapperCol}
               labelCol={labelCol}
            />
        </div>
    </div>
</LoginWarp>)

export default Login
