import ProTable, { ActionType } from "@ant-design/pro-table"
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"


import { ProFormDependency } from '@ant-design/pro-form';
import { filterObj, getToken, productApi, setHref } from "@utils/util"
import { Button, message, Modal, Popover, Tabs } from "antd"
import React, { useRef, useState } from "react"
import { columnsFn } from "./data"
import ProForm, {
    ModalForm,
    ProFormText,
    ProFormSelect,
    ProFormRadio,
    ProFormCascader,
    ProFormTextArea,
    ProFormDateTimePicker,
    ProFormCheckbox,
    ProFormUploadButton
} from '@ant-design/pro-form';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { BASEURL, smsGlobalSendOnlinePageList, smsGlobelSendDownLoadFailPhone, smsTempPageList, smsGlobalSendOnLine, smsSendCancel } from "@api/index"
import { SendMsgPreview, SMSTemplateEditor } from "@components/Common"
import FormItem from "antd/es/form/FormItem";
import { MsgSendType, MsgSendTypeGlobal } from "@components/Common/SendMsgCpn";
import { sendType, globalSendType } from "@components/MyProduct/SMS/Manage/cpnts/SendOnline/data";
import { handleDvmCommonListResult, handleDvmCommonPageListResult } from "@utils/request";
import { useHistory } from "react-router-dom";
import { stringify } from "qs";
import { handleSmsTempHtmlToData } from "@components/Common/SMSTemplateEditor";
import { CERT_COMPANY_FAIL, CERT_PERSON_FAIL, calcCertStatus, globalSmsTypeOptions } from "@utils/enum";
import { asyncSetUserInfo, setTaskObj } from "@store/actions";
import { useDispatch, useSelector } from "react-redux";
import { fileExportUrl } from "@api/baseUrl";
import { PopoverContent } from "../TemplateManage";
import { StateType } from "@store/index";



// 接口请求的 api 层
const getApi = productApi(smsGlobalSendOnlinePageList)  // 查询的接口封装
const addUpdateApi = productApi(homeTableAddUpdate) // 列表数据的新增/编辑 接口
const delApi = productApi(homeTableDel) // 表单数据的删除 接口
const downLoadFailPhoneApi = productApi(smsGlobelSendDownLoadFailPhone) // 下载失败号码
const sendOnLineApi = productApi(smsGlobalSendOnLine) // 在线发送
const getListApi = handleDvmCommonPageListResult(getApi)
const cancelApi = productApi(smsSendCancel) // 取消发送任务

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
// 添加群发任务
interface AddBatchSendTaskProps extends ModalFormProps {
    type?: '国际短信' | '国内短信',  // 双亚需求: 区分国内 / 国际 短信 添加群发任务 数据 2024.12.17
    params?: any;
    onSuccess?: () => void;
}
const AddBatchSendTask = ({ params, onSuccess, type, ...rest }: AddBatchSendTaskProps) => {
    const formRef = useRef<ProFormInstance>();
    const [templateText, setTemplateText] = useState('')
    const [phoneList, setPhoneList] = useState([])
    // const [hasVariable, setHasVariable] = useState(false)

    const onFinish = async (values: any) => {
        const data = { 
            ...values,
            ...params,
            file: values.file ? values.file[0]?.originFileObj || '' : '' ,
            phone: values.typeCode === 3 ? phoneList : null
        }
        const { success } = await sendOnLineApi(
            filterObj(data),
            true,
            () => {
                clearData()
                onSuccess ? onSuccess() : null
            }
        )
        return success
    }

    const validTime = (rule: any, value: Date, callback: any) => {
        const userTime = new Date(value).valueOf()
        const sysTime = new Date().valueOf()
        if (userTime < sysTime) {
            callback('选择时间要大于当前5分钟后的时间')
        } else if (userTime < sysTime + 300000 && userTime > sysTime) {
            callback('选择时间要大于当前5分钟后的时间')
        } else {
            callback()
        }
    }
    const Explain_1 = (
        <div>
            ①选择已经审核通过的模板。<br />
            ②如果短信模板有变量，仅支持上传文件。<br />
            ③如果短信模板无变量，支持上传文件、通讯录或者手动输入号码 。<br />
        </div>
    )
    const Explain_2 = (
        <div>
            ①可在下方直接输入“模板内容”。<br />
            ②上传手机号码的方式：excel上传/通讯录/手动输入。<br />
            ③不支持有变量的短信内容 。<br />
            ④任务提交以后进入待审核,审核通过后将立即执行发送任务（1小时内审核） 。<br />
        </div>
    )
    const templateChange = (res: any) => {
        const { content } = handleSmsTempHtmlToData(res) as any
        setTemplateText(content)
        formRef?.current?.setFieldsValue({ templateContent: content })
    }
    // const loadData = async (selectedOptions:any) => {
    //     const targetOption = selectedOptions[selectedOptions.length - 1];
    //     targetOption.loading = true;
    //     // load options lazily
    //     // async () => {
    //     const { result } = await smsTempPageList({pageNum: 1, pageSize: 999, utlSmsArea: 1,utlSmsType: targetOption.value, utlVerifyState:2}) as any
    //     const res = handleDvmCommonListResult(result)
    //     console.log(res)
    //     res.length === 0 && message.info('当前类型下还没有模板')
    //     targetOption.loading = false;
    //     targetOption.children = res?.map(({ utlCode: value, utlName: label, utlContent }: any) => ({ label, value, utlContent }))
    //     setOptions([...options])
    //   };
      const clearData = () => {
        formRef.current?.resetFields()
        setTemplateText('')
      }
    return (<ModalForm<any>
        layout="horizontal"
        width={1000}
        formRef={formRef}
        {...layout}
        onFinish={onFinish}
        preserve={false}
        modalProps={{
            onCancel: clearData
        }}
        {...rest}>
        <SendMsgPreview msgContent={templateText} type={'global'}/>
        <div style={{ width: '60%' }}>

            <ProFormDependency name={['sendTypeCode', 'sendTimeType', 'template', 'saveTemplate']}>
                {({ sendTypeCode, sendTimeType, template, saveTemplate }: any) => {
                    return (
                        <>
                            <ProFormSelect
                                width="md"
                                name="sendTypeCode"
                                label="选择群发方式"
                                initialValue={1}
                                // showSearch 
                                // extra={<Popover content={sendTypeCode === 2 ? Explain_2 : Explain_1} title="群发方式说明">
                                //     <div className="text-blue-500 mt-2 relative float-right pr-24">群发方式说明</div>
                                // </Popover>
                                // }
                                fieldProps={{
                                    // mode: 'multiple', 
                                    allowClear: false,
                                    placeholder: '请选择群发方式',
                                    defaultValue: 1,
                                    onChange: ()=> setTemplateText('')
                                    // showSearch: true, 
                                    // filterOption: false
                                }}
                                options={type === '国际短信' ? globalSendType : sendType}
                                rules={[{ required: false, message: '请选择群发方式' }]}
                            />

                            {/* <ProFormText
                                width="md"
                                name="categoryId"
                                label="任务名称"
                                rules={[{ required: true, message: '请输入任务名称' }]}
                                placeholder={sendTypeCode === 2 ? '请输入任务名称，将自动保存为模板名称' : '请输入任务名称'}
                            /> */}
                            {/* 短信类型 */}
                            <ProFormSelect
                                width="md"
                                name="msgType"
                                label="短信类型"
                                showSearch
                                request={
                                    async () => {
                                    return globalSmsTypeOptions
                                }
                                }
                                fieldProps={{
                                    placeholder: '请选择短信类型',
                                    showSearch: true,
                                    filterOption: false
                                }}
                                options={[]}
                                rules={[{ required: false, message: '请选择短信类型' }]}
                            />

                            {/* 模板内容 */}
                            {
                                sendTypeCode === 2 ? <FormItem
                                    name="templateContent"
                                    // fieldProps={{
                                    initialValue={1}
                                    rules={[{ required: true, message: '' }]}
                                    // }}
                                    label="模板内容"
                                    extra={`模版中不允许出现 "$", 避免被识别为变量!`}
                                    >
                                    <div style={{ width: '90%' }}>
                                        <SMSTemplateEditor popoverContent={PopoverContent} onChange={(res: any) => templateChange(res)} />
                                    </div>
                                </FormItem> : null
                            }

                            {/* 模板是否保存 */}
                            {
                                sendTypeCode === 2 ?
                                    <ProFormCheckbox.Group
                                        name="saveTemplate"
                                        label="保存模板"
                                        options={[{label: '将输入的模板内容同步提交到模板', value: 1}]}
                                    /> : null
                            }
                            {/* 模板名称 */}
                            {
                                saveTemplate && saveTemplate[0] === 1 && sendTypeCode === 2 ?
                                <ProFormText 
                                name="templateName" 
                                rules={[{ required: true, message: '请输入模板名称' }]}
                                label="模板名称" 
                                placeholder="请输入模板名称" /> : null
                            }



                            {/* 短信模板 */}
                            {sendTypeCode === 2 ? null :
                                <ProFormSelect
                                    width="md"
                                    name="templateCode"
                                    label="短信模版"
                                    showSearch
                                    fieldProps={{
                                        placeholder: '请选择短信模版',
                                        showSearch: true,
                                        filterOption: false,
                                        onChange:(value: any, option: any)=> setTemplateText(option ? option.utlContent: '')
                                    }}
                                    options={[]}
                                    request={
                                        async () => {
                                        const { result } = await smsTempPageList({pageNum: 1, pageSize: 999, utlSmsArea: 2,utlVerifyState:2}) as any
                                        const res = handleDvmCommonListResult(result)
                                        return res?.map(({ utlCode: value, utlName: label, utlContent }: any) => ({ label, value, utlContent }))
                                      }
                                    }
                                    rules={[{ required: false, message: '请选择短信模版' }]}
                                />
                                // <ProFormCascader
                                //     width="md"
                                //     name="templateCode"
                                //     label="短信模版"
                                //     fieldProps={{
                                //         placeholder: '请选择短信模版',
                                //         loadData: loadData,
                                //         options:options,
                                //         onChange:(value: any, option: any)=> setTemplateText(value? option[1].utlContent : '')
                                //     }}
                                   
                                //     rules={[{ required: false, message: '请选择短信模版' }]}
                                // />
                            }
                            {/* 套餐分类 */}
                            {/* {sendTypeCode === 2 ?
                                <ProFormSelect
                                    width="md"
                                    name="customerIds"
                                    label="套餐分类"
                                    showSearch
                                    fieldProps={{
                                        mode: 'multiple',
                                        placeholder: '请选择短信模版',
                                        showSearch: true,
                                        filterOption: false
                                    }}
                                    options={[]}
                                    rules={[{ required: false, message: '请选择短信模版' }]}
                                /> : null
                            } */}
                            {/* 模板内容 */}
                            {/* {sendTypeCode === 2 ?
                                <ProFormTextArea
                                    width="md"
                                    name="customerIds"
                                    label="模板内容"
                                    fieldProps={{
                                        placeholder: '请输入模板内容，不含变量',
                                    }}
                                    rules={[{ required: false, message: '请输入模板内容，不含变量' }]}
                                /> : null
                            } */}
                            {/* 上传号码 */}
                            <FormItem
                                name="typeCode"
                                // fieldProps={{
                                initialValue={1}
                                rules={[{ required: true, message: '' }]}
                                // }}
                                label="上传号码">
                                <MsgSendTypeGlobal setPhoneList={setPhoneList} onlyExcel={/\$\{(.+?)\}/g.test(templateText)} formRef={formRef} />
                            </FormItem>
                            {/*  */}
                            <ProFormRadio.Group
                                name="sendTimeType"
                                label="发送时间"
                                initialValue="1"
                                options={[
                                    {
                                        label: '立刻',
                                        value: '1',
                                    },
                                    {
                                        label: '定时发送',
                                        value: '2',
                                    },
                                ]}
                            />
                            {
                                sendTimeType === '2' ?
                                    <ProFormDateTimePicker
                                        name="taskTime"
                                        label="日期时间"
                                        rules={[{ required: true, message: '请选择时间' }, { validator: validTime }]}
                                    />
                                    : null
                            }
                        </>

                    );
                }}
            </ProFormDependency>
        </div>
    </ModalForm>)
}



// 在线发送
export default () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({ visible: false, title: '新增', params: {} })
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    const { baseInfo, certInfo } = useSelector((store: StateType) => store?.userInfo) as any
    const { userNature: type, verifyState: status } = (baseInfo || {}) as any
    const STATUS = calcCertStatus(type, status)
    // operate 函数为增删改查需要处理的业务
    const operate = async (type: any, record: any) => {
        const { tiId, sendTime, createTime } = record || {}
        if (type === '新增') {
            // 需求方说 认证失败{个人/企业} 不允许新增
            if ([CERT_PERSON_FAIL, CERT_COMPANY_FAIL].includes(STATUS)) return message.warning('实名认证失败，不允许操作')
            setModalProps({
                ...modalProps,
                title: '新增',
                params: {},
                visible: true,
            })
            console.log('需要处理的新增业务')
        } else if (type === '修改') {
            setModalProps({
                ...modalProps,
                title: '修改',
                params: { tiId },
                visible: true,
            })
            console.log('需要处理的修改业务')
        } else if (type === '发送明细') {
            const queryParam = { tiId, taskOrApiDetails: 1, sendTime, createTime }
            history.push(`/product/globalSms/sendDetail?${stringify(queryParam)}`)
        } else if (type === '下载失败号码') {
            await downLoadFailPhoneApi({tiId}, false, (data: any) => {
                // const { file: filename } = data || {}
                // const url = fileExportUrl(filename)
                // filename && window.open(url)
                const { exportType, serialNumber } = data || {} //exportType: 1 同步，2 异步
                if (exportType === 1) {
                    dispatch(setTaskObj('ADD_TASK', {[serialNumber]: {}}))
                } else if (exportType === 2) {
                    Modal.confirm({
                        title: '温馨提示',
                        content: <div>后端已接收该导出任务，具体请前往<a onClick={() => setHref('/account/taskList')}>导出记录</a>查看</div>,
                        okText: '确认',
                        centered: true,
                        cancelText: '取消',
                    });
                }
            })
        } else if (type === '删除') {
            const tiIds = record ? [tiId] : selectedRowKeys
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async () => {
                    await delApi({ tiIds: tiIds.toString() }, true, () => {
                        console.log('数据删除成功, 可以执行回调了')
                        reFresh()
                    })
                },
            });
        } else if (type === '取消发送') {
            Modal.confirm({
                title: '确认要取消发送定时任务吗?',
                content: '',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async () => {
                    await cancelApi({ taskId:tiId }, true, () => {
                        reFresh()
                    })
                },
            });
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({ ...params }, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }
    // const getListApi = handleDvmCommonPageListResult(getApi)

    const columns = columnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        setModalProps({
            ...modalProps,
            visible: false
        })
        dispatch(asyncSetUserInfo()) as any // 获取账号权限信息
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => selectedRowKeys.length
    return (<ProTable<any>
        style={{ flex: 1, overflow: 'scroll' }}
        scroll={{ x: 1300 }}
        columns={columns}
        params={{}} // 查询所需要的额外参数
        request={getListApi}
        options={{ reload: false, density: false, setting: false }}
        toolbar={{
            actions: [
                [CERT_PERSON_FAIL, CERT_COMPANY_FAIL].includes(STATUS) ? <Button type='primary' onClick={() => message.warning('实名认证失败，不允许操作')}>添加群发任务</Button> :
                <AddBatchSendTask onSuccess={onSuccess} type="国际短信" key="btn0" title="添加国际短信群发任务" trigger={<Button type='primary'>添加群发任务</Button>} />,
                // <Button key="btn0" type='primary' onClick={() => operate('新增', null)}>添加群发任务</Button>,
                // <Button key="btn1" type='primary' disabled={!calcDelDisabled()} onClick={() => operate('删除', null)}>删除</Button>,
            ],
        }}
        rowKey="tiId"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        search={{
            optionRender: (searchConfig, formProps, dom) => [...dom.reverse()]
        }}
        // rowSelection={{
        //     preserveSelectedRowKeys: true,
        //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
        // }}
    />)
}

