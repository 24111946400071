import { Alert, Button, Tabs } from "antd"
import React, { useRef, useState } from "react"
import ProTable, { ActionType } from "@ant-design/pro-table";
import { productApi } from "@utils/index";
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home";
import { columnsFn } from "./data";
import { ExportButton } from "@components/Common";
import { BASEURL, smsSendDetailPageList } from "@api/index";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { Description } from "@components/Common/AlertTip";

// 接口请求的 api 层
const getApi = productApi(smsSendDetailPageList)  // 查询的接口封装
const addUpdateApi = productApi(homeTableAddUpdate) // 列表数据的新增/编辑 接口
const delApi = productApi(homeTableDel) // 表单数据的删除 接口

export default () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({visible: false, title: '新增', params: {}})
    const formRef = useRef<ProFormInstance>()
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    const [total, setTotal] = useState(0)
    // operate 函数为增删改查需要处理的业务
    const operate = (type: any, record: any) => {
        const { id } = record || {}
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total } = data || {}
        setTotal(total)
        return {
            data: values || [],
            success: true,
            total: total > 50000 ? 50000 : total
        }
    }

    const columns = columnsFn(operate) // 表格列配置
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const getQueryParams = () => ({
        // @ts-ignore
        ...formRef.current?.getFieldsFormatValue()
    })
    return (<>
    {/* <Alert
        description={<Description title="查询结果仅支持显示5万条数据，想要查看更多数据，可导出数据查看。" listTips={[]} />}
        type="warning"
    /> */}
    <ProTable<any>
        style={{flex: 1, overflow: 'scroll'}}
        scroll={{x: 1300}}
        columns={columns}
        formRef={formRef}
        params={{}} // 查询所需要的额外参数
        request={getListApi}
        options={{reload: false, density: false, setting: false}}
        headerTitle={<>数据总条数: {total} 条；<span style={{fontSize: 14, color: 'coral'}}>(
            {/* 查询结果仅支持显示5万条数据，想要查看更多数据，可导出数据查看。 */}
            查询结果仅支持显示5万条数据，导出数据最大支持导出一万条数据，如果您需要查看更多数据，建议可以分隔时间导出查看或联系平台运营协助导出查看。
            )</span></>}
        toolbar={{
            actions: [
                <ExportButton key="export" type='primary' action={`${BASEURL}/portal/domesticMessage/exportSendMessageDetails`} param={getQueryParams} btnText="导出" />
            ],
        }}
        // rowKey="tiId"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        search={{
            optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
        }}
        // rowSelection={{
        //     preserveSelectedRowKeys: true,
        //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
        // }}
    />
    </>)
}
