import { fileHttpUrl } from '@api/baseUrl'
import { message } from 'antd'
import { parse } from 'qs'
import { TOKENKEY } from './request'
import FormData from 'form-data'
import { Base64 } from 'js-base64'

export const acceptImg = ".png, .jpg, .jpeg, .bmp, .gif" // 文件上传: 只上传图片
export const acceptExcel = ".xls, .xlsx"
export const acceptPdf = ".pdf"

export const ImgExt = /.(png|jpg|jpeg|bmp|gif)$/
export const ExcelExt = /.(xls|xlsx)$/
export const PdfExt = /.(pdf)$/

// 通用正则判断
// export const fixedPhone = RegExp(/^\d{3}-\d{7,8}|\d{4}-\d{7,8}$/)
export const fixedPhone = RegExp(/^\d{3,4}(-)?\d{7,8}$/)
export const phoneReg = new RegExp(/^1[3456789][\d*]{5}[\d]{4}$/)
export const cmPhoneReg = new RegExp(/^((((13[4|5|6|7|8|9])|(15[0|1|2|4|7|8|9])|(18[2|3|4|7|8])|(147)|(1(78|48|49)))\d{8})|((170(3|5|6))\d{7}))$/) // 移动号码
export const cuPhoneReg = new RegExp(/^((((13[0|1|2])|(15[5|6])|(18[5|6])|(1(44|45|76|71)))\d{8})|(((17(04|07|08|09)))\d{7}))$/) // 联通号码
export const ctPhoneReg = new RegExp(/^((((1(33|49|73))|(153)|(18[0|1|9])|(177))\d{8})|((170(0|1|2))\d{7}))$/) // 电信号码
export const emailReg = new RegExp(/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/)
export const idCardNumReg = new RegExp(/^(\d{18,18}|\d{15,15}|\d{17,17}X)$/) // 身份证号码
export const doubleNumReg = new RegExp(/^[0-9]+(.[0-9]{1,2})?$/) // 非负整数(2位小数)
export const floatNumReg = new RegExp(/^[0-9]+(.[0-9]{1,4})?$/) // 非负整数(4位小数)

// 系统登录名正则
export const userNameReg = new RegExp(/^[\u4e00-\u9fa5_a-zA-Z0-9_]{6,30}$/) // 字母或数字
// export const userNameReg = new RegExp(/(^([\u4e00-\u9fa5a-zA-Z\d+]+)$)/) // 用户名正则
export const illegal = new RegExp(/#|[~～]|[!！]|[?？]|\.\.|--|__|－|＿|※|▲|△| | |@/) // 非法正则(特殊符号)

// 系统登录密码正则
// export const pwdReg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/) // 必须包含字母和数字 6-16位
// export const pwdReg = new RegExp(/(^([a-zA-Z\d+]+)$)/) // 密码正则校验: 只可输入英文、数字或组合
// export const pwdReg = new RegExp(/(?!^(\d+|[a-zA-Z]+|[!@#$%^&*]+)$)^[\w!@#$%^&.*_?]{6,20}$/) // 密码至少包含英文、数字、符号中的两种
export const pwdReg = new RegExp(/(?!^(\d+|[a-zA-Z]+|[!@#$%^&.*_?]+)$)^[\w!@#$%^&.*_?]{6,20}$/) // 密码至少包含英文、数字、符号中的两种
// export const pwdReg = new RegExp(/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![_]+$)[0-9_A-Za-z]{6,20}$/) // 密码至少包含英文、数字、符号中的两种
export const homePageReg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/ // 官网正则判断
export const mobileNumberMask = (str: string) => (str||'').replace(/(\d{3})\d*(\d{4})/, '$1****$2') // 手机号脱敏
export const idCardNumberMask = (str: string) => (str||'').replace("(?<=\\w{3})\\w(?=\\w{4})","*") // 身份证号脱敏
export const emailMask = (str: string) => str.replace("(\\w+)\\w{5}@(\\w+)","$1***@$2") // 邮箱脱敏

export const strMask = (word: string, start?: number, end?: number) => {
    const str = '' + word
    const len = str.length
    if (len === 1) {
        return `*`
    }
    if (len === 2) {
        return `${str[0]}*`
    }
    if (len === 3) {
        return `${str[0]}*${str[2]}`
    }
    const startLen = (start || (len/3).toFixed(0)) as number * 1
    const endLen = len - (end||2)
    const startStr = str.substr(0, startLen)
    const endStr = str.substr(endLen, len)
    const maskStr = '*'.repeat(endLen - startLen)
    return `${startStr}${maskStr}${endStr}`
}
export const imgRegex = /\.(bmp|gif|jpg|jpeg|png)$/
export const isImageFile = (ext: string) => imgRegex.test(ext)
export const charLength = (str: string) => !str ? 0 : str.replace(/[^\x00-\xff]/g, '**').length; // 判断字符串长度(中文占2个字符)

export const IsArray = Array.isArray

export function sleep(ms: number){
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export function isDef<T>(val: T): val is NonNullable<T> {
    return val !== undefined && val !== null
}

export function isPlainDef(val: unknown): boolean {
    return isDef(val) && String(val).trim() !== ''
}

export function isFunction(val: unknown): val is Function {
    return typeof val === 'function'
}

export function isObject(val: unknown): val is Record<any,any> {
    return val !== null && typeof val === 'object'
}

export function isPromise<T = any>(val: unknown): val is Promise<T> {
    return isObject(val) && isFunction(val.then) && isFunction(val.catch)
}

export function isPlainObject(val: unknown) {
    return val !== null && typeof val === 'object' && Object.keys(val as Object).length > 0
}

// 判断是否为浏览器环境(服务端渲染会用到)
export function isBrowser() {
    return !!(typeof window !== 'undefined' && window.document && window.document.createElement);
}

// 判断是否是移动端
export function isMobile(){
    const reg = new RegExp(/iPhone|iPad|iPod|Android/i)
    return reg.test(navigator.userAgent)
}

export function setStorage(key: string, value: any) {
  isBrowser() && localStorage.setItem(key, value)
}

export function getStorage(key: string) {
  return (isBrowser() && localStorage.getItem(key))
}

export function removeStorage(key: string) {
  isBrowser() && localStorage.removeItem(key)
}
export function getToken() {
    return getStorage(TOKENKEY)
}

export function removeToken() {
    removeStorage(TOKENKEY)
}

export function tranformHomePage(url: string) {
    return homePageReg.test(url) ? url : `//${url}`
}

export function setHref(url: string) {
    isBrowser() && (window.location.href = url)
}

export function windowOpen(url: string) {
    isBrowser() && (window.open(url, '_blank'))
}

export const parseSearch = (search: string = '') => parse((search||'').slice(1)) as Record<string, any>;

export const stringifySearch = (data:any) => {
    let url = ''
    for (let k in data) {
      let value = data[k] !== undefined ? data[k] : ''
      url += `&${k}=${encodeURIComponent(value)}`
    }
    return url ? url.substring(1) : ''//去调前面的'&'符号
  }

export function handleIds(ids: string[], s: string){
    const targetIds = (ids||[]).filter(v => v.startsWith(s))
    return (targetIds||[]).map((v: string) => v.slice(1))
}

export function getPersistenceKeyFromStorage(key: string) {
    return JSON.parse( getStorage(key) || '{}' )
}

// 解决浮点数运算bug
export function strip(num: number, precision: number = 12) {
    return +parseFloat(num.toPrecision(precision))
}

// 解决浮点数运算: 不舍四五人     precision：需要精确到的小数点后位数  例1.02 -> 2
export function toFixedNoRound(num: number, precision:number = 2) {
    const pointPosi = (num + '').indexOf('.')
    const calcNum = pointPosi < 0 ? num.toFixed(2) : (((num+'').substring(0, pointPosi+precision +1) as any) * 1).toFixed(precision)
    return calcNum
}

// 金额数字千分位格式化 10000 => 10,000.00
export function amountFormat(num: number | string) {
    const amount = Number(num).toFixed(2)
    return `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// 请求转换为from-data格式
export function fromData(data:any) {
    let formData = new FormData()
    Object.keys(data).forEach((element:any) => {
      formData.append(element, data[element])
    })
    return formData
}

// 去除对象中值为 null undefined 的空值
export function filterObj(data:any) {
    const filterData = Object.keys(data)
	.filter((key) => data[key] !== null && data[key] !== undefined && data[key] !== '')
	.reduce((acc, key) => ({ ...acc, [key]: data[key] }), {});
    return filterData
}

export function toFormData(data: any) {
    if (data === null) return null;
    return Object.keys(data).reduce((formData, item) => {
      if (item === 'fileList') { //特殊判断如果内容为files数组，就让里面值不用走JSON.stringify
        data[item] && data[item].forEach((curr: { originFileObj: string | Blob }) => {
            formData.append('file', curr.originFileObj);
          });
      } else {
        formData.append(item, data[item]);
      }
      return formData;
    }, new FormData());
};

/**
 * @description: 转换路由: /index/index2/index3   => ['/index', '/index/index2', '/index/index2/index3']
 * @param {string} path
 * @return {string[]}
 */
export function getPathStage(path: string) {
    const arr: string[] = [];
    let key = '';
    //@ts-ignore
    path.replace(/\/\w+(?=\/)/g, item => arr.push(key+=item))
    return arr;
}

export function openInNewTab(url: string) {
    if (!isBrowser()) return
    const win = window.open(url, '_blank');
    win?.focus()
}

export function ellipsis(str='', len=50) {
    const newStr = str || ''
    const strLen = newStr.length
    return `${(newStr||'').substring(0, len)}${strLen > len ? '...' : ''}`
}

export function downLoadWithATag(url: string) {
    if(isBrowser()){
        const elink = document.createElement('a')
        elink.style.display = 'none'
        elink.href = url
        document.body.appendChild(elink)
        elink.click()
        document.body.removeChild(elink)
    }
}

// 树状结构扁平化递归
export function treeDataToFlatArr(data: any[], withChildren: Boolean = true) {
    const arr: any[] = []
    const deps = (data: any[]) => (data||[]).forEach(v => {
        const { children, ...rest } = v
        arr.push(withChildren ? v : rest)
        v.children && deps(v.children || [])
    })
    deps(data)
    return arr
}

// 格式化处理成

/**
 * @description: 获取权限 code list
 * @param {any} data
 * @return {(string|number)[]}
 */
export function getAuthCodeArr(data: any[]) {
    const arr: any[] = [-1]
    const deps = (data: any[]) => (data||[]).forEach(({menuId, children}) => {
        arr.push(menuId)
        children && deps(children||[])
    })
    deps(data)
    return arr
}

//
export const handleCommonTreeData = (treeData: any, handleItem: (v: any) => Object) => {
    const allLeafArr: any[] = []
    const flatArr: any[] = []
    const deps = (data: any[]) => (data||[]).forEach((v) => {
        const { children } = v
        v = (handleItem && handleItem(v)) || v
        flatArr.push(v)
        if (children && children.length) {
            deps(children||[])
        } else {
            allLeafArr.push(v)
        }
    })
    deps(treeData)
    return {
        treeData,
        flatArr,
        allLeafArr
    }
}

// 处理省市区结构树
export function handleAreaTree(data: any[]) {
    const deps = (data: any[]) => (data||[]).forEach((v) => {
        const {saId, saName, saPid, children} = v
        Object.assign(v, {
            id: saId,
            value: saId,
            title: saName,
            label: saName,
            pId: saPid,
        })
        children && deps(children||[])
    })
    deps(data)
    return data
}

// 获取产品树
export function handleProductTree(data: any[]) {
    const allProduct: any = []
    const proTypeTreeData: any = []
    const deps = (data: any[]) => (data||[]).map((v) => {
        const {ptId, ptName, ptPid, piId, piName, children} = v
        const label = piName || ptName
        const pId = v.hasOwnProperty('piId') ? `t${ptId}` : `t${ptPid}`
        const id = v.hasOwnProperty('piId') ? `p${piId}` : `t${ptId}`
        Object.assign(v, {
            id,
            value: id,
            title: label,
            label,
            pId,
        })
        if (piId) { // 收集所有的产品节点
            allProduct.push(v)
        } else {
            proTypeTreeData.push({...v, children: undefined})
        }
        if (children) {
            deps(children||[])
        }
    })
    deps(data)
    return {
        treeData: data,
        allProduct,
        proTypeTreeData
    }
}

export const productApi = (fn: Function) => {
    return async (param: any, showMsg?: boolean, successCB?: Function) => {
        const { current: pageNum, ...rests } = param
        // 如果参数不是对象  会被转换为对象  所以添加判断
        try {
          const { code, message: dvmMsg, msg, success, data, ...rest } = await (fn(pageNum ? {pageNum, ...rests} : param))
          const res = {
              code,
              dvmMsg,
              success,
              data
          }
          const type = success ? 'success' : 'error'
          showMsg && success && message[type](msg || dvmMsg)
          success && successCB && successCB(data)
          return { type, success, msg: msg || dvmMsg, data, ...rest }
        } catch (err) {
          return {
            type: 'error',
            success: false,
            msg: '接口错误',
          }
        }
    }
}

// 校验系统登录名
export function validatorUserName(rules: any, val: string, callback: Function) {
    if (!val) {
        return Promise.reject('请输入用户名')
    }
    if (val.length > 5 && val.length < 31) {
        return Promise.resolve()
    } else {
        return Promise.reject('6-30个字符,支持汉字、数字、字母、标点符号等')
    }
}

// 校验手机号码：多个用 , 分割
export function validatorPhones(num: number, ) {
    return (rules: any, val: string, callback: Function) => {
        if (!val) {
            return Promise.reject('请输入手机号')
        }
        const phones = val?.split(',').map(v => v.trim())
        if (phones.length > num) {
            return Promise.reject(`最多输入${num}个手机号`)
        }
        
        if (phones?.some(phone => !phoneReg.test(phone))) {
            return Promise.reject('请输入正确的手机号')
        }

        return Promise.resolve()
    }
}
// 复制内容到粘贴板
export function copyText (data: string) {
    if (!isBrowser() ) {
        return
    }
    const pEle = document.createElement('p');
    pEle.innerHTML = data || '';
    document.body.appendChild(pEle);

    const range = document.createRange(); // 创造range
    window.getSelection()?.removeAllRanges(); //清除页面中已有的selection
    range.selectNode(pEle); // 选中需要复制的节点
    window.getSelection()?.addRange(range); // 执行选中元素

    const copyStatus = document.execCommand("Copy"); // 执行copy操作
    message.success(copyStatus ? '复制成功' : '复制失败'); // 不需要提示 可注释
    document.body.removeChild(pEle);
    window.getSelection()?.removeAllRanges(); //清除页面中已有的selection
  }

// 校验系统登录密码
export function validatorUserPassword(rules: any, val: string, callback: Function) {
    if (!val) {
        return Promise.reject('请输入密码')
    }
    if (pwdReg.test(val)) {
        return Promise.resolve()
    } else {
        return Promise.reject(['建议使用数字、字母及特殊符号(!@#$%^&*._?非空格)2种以上的组合，6-20个字符'])
    }
}

// 截取文本，超出省略号
export const sliceText = (text: string, length:number = 25) => {
    return (text || '').length > length ? text.slice(0, length) + '...': text
}

// 在线客服桥接
export function customerOnline(){
    (function(a: any, b: any, c: any, d: any, e?: any, j?: any, s?: any) {
        a[d] = a[d] || function() {
            (a[d].a = a[d].a || []).push(arguments)
        };
        j = b.createElement(c),
            s = b.getElementsByTagName(c)[0];
        j.async = true;
        j.charset = 'UTF-8';
        j.src = 'https://static.meiqia.com/widget/loader.js';
        s.parentNode.insertBefore(j, s);
    })(window, document, 'script', '_MEIQIA');
    // @ts-ignore
    _MEIQIA('entId', '331cec51cf530096a00e14b7030ab701');
}

// 设置网站ico
export function setWebsiteIco(ico?: string) {
    if (!isBrowser()) return
    const linkNode = document.querySelector('link[rel="icon"]') as any
    ico && linkNode && (linkNode.href = fileHttpUrl(ico))
}

// 设置网站title
export function setWebsiteTitle(title?: string) {
    title && isBrowser() && (document.title = title)
}

// 设置网站title
export function setWindowHref(href?: string) {
    href && isBrowser() && (window.location.href = href)
}


// 设置网站个性化信息
type WebsiteInfo = {
    ico?: string;
    title?: string;
}
export function setWebsite({ico, title}: WebsiteInfo) {
    setWebsiteIco(ico)
    setWebsiteTitle(title)
}

// 处理 table 表格排序
export function handleTableSorter(sorter: any){
    const keys = Object.keys(sorter)
    const sortField = keys[0]
    const asc = sorter[sortField] === 'ascend'
    return {
        asc,
        sortField,
        sorter: sortField ? sorter : null
    }
}


/**
 * 判断字符串是否为空
 * @param {*} str 
 * @returns 
 */
export function strIsEmpty(str: string){
	return str == undefined || str == null || str.length <= 0
}

/**
 * 
 * @param {*} str 
 * @returns 
 */
export function encryption(str: string){
	if(strIsEmpty(str)){
		return '';
	}
	else{
		let char1 = Math.floor(Math.random() * 10);
		let char2 = Math.floor(Math.random() * 10);
		if(str.length > 4){
			str = str.substr(0, 3) + char1 + str.substr(3);
			if(str.length > 8){
				str = str.substr(0, 6) + char2 + str.substr(6);
			}
		}
		return str;
	}
}

// 密码加密要兼容老版本的加密规则，因此不能使用 md5 加密了，要使用以下强哥的加密规则:
/**
 * 生成加密字符串（自定义的加密方法）
 * @param {*} str 
 * @returns 
 */
export function encryptionString(value: string){
	if(strIsEmpty(value)){
		return '';
	}
	else{
		let json = {
			t: new Date().getTime(),
			t1: Math.floor(Math.random() * 100),
			u: value,
			n: 10001
		};
		return encryption(Base64.encode(JSON.stringify(json)));
	}
}