import { isBrowser } from "@utils/util";
import React from "react";
import { useEffect, useRef } from "react";

type RichTextProps = {
    html?: any
}
const RichText = ({ html }: RichTextProps) => {
    const scriptContainer = useRef<any>(null);
   
    useEffect(() => {
        if (scriptContainer.current && isBrowser()) {
            const scripts = [...scriptContainer.current.getElementsByTagName('script')];
            scripts.forEach(script => {
              const newScript = document.createElement('script');
              newScript.innerHTML = script.innerHTML;
              script.parentNode?.replaceChild(newScript, script);
            });
        }
    }, []);
   
    return (<div ref={scriptContainer} dangerouslySetInnerHTML={{ __html: html }} />);
  };
   
  export default RichText;